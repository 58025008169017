import type { IconComponent } from "@/types";

export const BASE: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg">
    <foreignObject height="100" width="100" x="0" y="0">
      <div
        style={{
          background:
            "conic-gradient(from 270deg, rgb(255 255 255), rgb(0 82 255))",
          borderRadius: "50%",
          height: "100%",
          width: "100%",
        }}
      />
    </foreignObject>
  </svg>
);
