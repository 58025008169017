"use client";

import { WebSocketProvider } from "ethers";
import { useCallback, useState } from "react";

import { CHAINS } from "@/constants";
import type { Address, Chain, Method } from "@/types";
import { fetch, toNumber } from "@/utils";

const REQUESTS: [Address, Method, decimals: number][] = [
  ["bankx", "genesis_supply", 18],
  ["bankx", "totalSupply", 18],
  ["cd", "LPBBonusPercent", 2],
  ["pid", "amountpaid1", 18],
  ["pid", "amountpaid2", 18],
  ["pid", "diff1", 18],
  ["pid", "diff2", 18],
  ["pid", "interest_rate", 6],
  ["pid", "xsd_price", 6],
];

export const useChains = (): [
  chains: Chain[],
  getValues: (ids: Chain["id"][]) => Promise<void>
] => {
  const [state, setState] = useState<Chain[]>(CHAINS);

  const getValues = useCallback(async (ids: Chain["id"][]): Promise<void> => {
    // reset values
    setState((prevState) =>
      prevState.map((chain) => {
        if (ids.includes(chain.id)) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { values, ...rest } = chain;

          return rest;
        }

        return chain;
      })
    );

    const chains = ids
      .map((id) => CHAINS.find((chain) => chain.id === id))
      .filter((chain) => chain !== undefined)
      // create new providers because previously used websockets may have closed
      .map<Chain>((chain) => ({
        ...chain,
        provider: new WebSocketProvider(chain.url, chain.id),
      }));
    const responses = await Promise.all(
      REQUESTS.map(([address, method, decimals]) =>
        Promise.allSettled(chains.map(fetch(address, method))).then(
          (responses) => responses.map(toNumber(decimals))
        )
      )
    );
    const values = chains.map((_, i) =>
      REQUESTS.reduce((acc, [, method], j) => {
        acc[method] = responses.at(j)!.at(i)!;

        return acc;
      }, {} as Record<Method, number | undefined>)
    );

    // set values
    setState((prevState) =>
      prevState.map((chain) => ({
        ...chain,
        values: values.at(chains.findIndex(({ id }) => id === chain.id)),
      }))
    );
  }, []);

  return [state, getValues];
};
