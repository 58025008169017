"use client";

import clsx from "clsx";
import { FunctionComponent, useState, VideoHTMLAttributes } from "react";
import { createPortal } from "react-dom";

import styles from "./BackgroundVideo.module.css";

const URL = "/videos/background";

export const BackgroundVideo: FunctionComponent<
  VideoHTMLAttributes<HTMLVideoElement>
> = ({ className, ...props }) => {
  const [root, setRoot] = useState<HTMLElement | null>(null);

  if (root) {
    return createPortal(
      <video
        {...props}
        aria-hidden
        autoPlay
        className={clsx(className, styles.root)}
        loop
        muted
        playsInline>
        <source src={`${URL}.mov`} type='video/mp4; codecs="hvc1"' />
        <source src={`${URL}.webm`} type="video/webm" />
      </video>,
      root
    );
  }

  return (
    <video
      {...props}
      hidden
      ref={() => {
        setRoot(document.getElementById("__next"));
      }}
    />
  );
};
