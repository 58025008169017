import type { IconComponent } from "@/types";

export const Rumble: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 40 43"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.0101 21.24C40.0101 25.01 38.0301 27.28 36.2001 29.07C31.8301 33.36 19.1201 42.19 11.3401 42.19C8.03006 42.19 4.72007 41.09 2.52007 35.57C0.320065 30.05 0.310059 21.23 0.310059 21.23C0.310059 21.23 0.310065 12.41 2.52007 6.88999C4.73007 1.36999 8.03006 0.269989 11.3401 0.269989C19.1201 0.269989 31.8301 9.09999 36.2001 13.39C38.0301 15.19 40.0101 17.45 40.0101 21.22V21.24ZM11.8501 16.65C11.5201 19.83 11.5201 22.65 11.8501 25.83C12.0801 28.07 14.4101 29.47 16.4701 28.55C18.6501 27.58 21.5201 26.09 24.5501 23.9C26.3501 22.6 26.3501 19.88 24.5501 18.58C21.5201 16.39 18.6601 14.89 16.4701 13.93C14.4101 13.02 12.0801 14.41 11.8501 16.65Z"
      fill="currentColor"
    />
  </svg>
);
