import type { IconComponent } from "@/types";

export const BNB: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="-20 -20 166.61 166.61"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="63.305" cy="63.305" fill="rgb(243 186 47)" r="83.305" />
    <path
      d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z"
      fill="rgb(255 255 255)"
    />
    <path
      d="m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z"
      fill="rgb(255 255 255)"
    />
    <path
      d="m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z"
      fill="rgb(255 255 255)"
    />
    <path
      d="m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z"
      fill="rgb(255 255 255)"
    />
    <path
      d="m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z"
      fill="rgb(255 255 255)"
    />
  </svg>
);
