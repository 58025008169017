"use client";

import clsx from "clsx";
import {
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
  useContext,
} from "react";

import { TabsContext } from "@/contexts";

import styles from "./Tabpanel.module.css";

export const Tabpanel: FunctionComponent<
  PropsWithChildren<
    HTMLAttributes<HTMLElement> & {
      label: string; // @todo ReactNode
    }
  >
> = ({ children, className, label, ...props }) => {
  const { activeLabel } = useContext(TabsContext);

  return (
    <section
      {...props}
      className={clsx(className, styles.root, {
        [styles.hidden]: activeLabel !== undefined && label !== activeLabel,
      })}>
      <h2 className="visually-hidden">{label}</h2>
      {children}
    </section>
  );
};
