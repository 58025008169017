import type { IconComponent } from "@/types";

export const ETH: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="rgb(98 126 234)" fillRule="evenodd" />
    <path
      d="M16.498 4v8.87l7.497 3.35z"
      fill="rgb(255 255 255)"
      fillOpacity=".602"
      fillRule="nonzero"
    />
    <path
      d="M16.498 4L9 16.22l7.498-3.35z"
      fill="rgb(255 255 255)"
      fillRule="nonzero"
    />
    <path
      d="M16.498 21.968v6.027L24 17.616z"
      fill="rgb(255 255 255)"
      fillOpacity=".602"
      fillRule="nonzero"
    />
    <path
      d="M16.498 27.995v-6.028L9 17.616z"
      fill="rgb(255 255 255)"
      fillRule="nonzero"
    />
    <path
      d="M16.498 20.573l7.497-4.353-7.497-3.348z"
      fill="rgb(255 255 255)"
      fillOpacity=".2"
      fillRule="nonzero"
    />
    <path
      d="M9 16.22l7.498 4.353v-7.701z"
      fill="rgb(255 255 255)"
      fillOpacity=".602"
      fillRule="nonzero"
    />
  </svg>
);
