import type { IconComponent } from "@/types";

export const Arbitrum: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="10 10 22.9 13.1"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4,27.9c6.3,0,11.3-5.1,11.3-11.3c0-6.3-5.1-11.3-11.3-11.3c-6.3,0-11.3,5.1-11.3,11.3 C10.1,22.8,15.2,27.9,21.4,27.9z"
      fill="rgb(44 55 75)"
    />
    <path
      clipRule="evenodd"
      d="M27.6,12.5c-0.1-0.1-5-3-5.6-3.2c-0.2-0.1-0.7-0.2-1-0.1c-0.3,0.1-5.5,3.1-5.7,3.3c-0.1,0.1-0.2,0.3-0.3,0.4 c-0.1,0.2-0.1,0.3-0.1,3.8l0,3.6l0.7,0.4c0.4,0.2,0.7,0.4,0.7,0.4c0.1,0,4.8-7.5,4.8-7.6c0-0.1-1.1-0.1-1.4,0 c-0.4,0.1-0.7,0.3-0.9,0.5c-0.1,0.1-0.7,1.1-1.5,2.2c-0.7,1.1-1.3,2.1-1.3,2.1c0,0,0-1,0-2.4v-2.5l0.1-0.1c0.2-0.2,5.3-3.1,5.5-3.1 c0.1,0,0.2,0,1.4,0.7c0.4,0.2,1.5,0.8,2.4,1.4c0.9,0.5,1.7,1,1.8,1.1l0.1,0.1v5.2l-2.5-3.9c-0.1-0.2-0.2-0.4-0.3-0.5l0,0l0,0 c0,0,0,0,0,0c0,0-0.1,0.2-0.7,1.2c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,0.2l0,0l0,0l0.1,0.1l0.1,0.1c0,0,0,0.1,0.1,0.1l0.2,0.3 c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.4c0,0,0,0,0,0.1l2.1,3.4l-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.1l-2.6-4l-0.1-0.1l-0.2,0.4l0,0 l-1,1.6l0,0l2,3.2c-0.3,0.2-2,1.4-2.1,1.4c-0.1,0-2.4-1.5-2.3-1.6c0,0,0.7-1.2,1.5-2.6l0,0c0.7-1.2,1.6-2.7,2.2-3.7 c0.7-1.2,1-1.8,1-1.8c0,0-0.5,0-1.1,0l-1,0l-2.3,3.8c-0.8,1.4-1.6,2.7-2,3.4l0,0C17.2,21,17,21.2,17,21.2l-0.1,0.2l1.8,1 c1,0.6,1.9,1.1,2.1,1.2c0.3,0.2,0.8,0.2,1,0.2c0.2-0.1,5.4-3.1,5.7-3.3c0.1-0.1,0.2-0.2,0.3-0.4l0.1-0.3l0-3.3l0-3.3l-0.1-0.2 C27.8,12.8,27.6,12.6,27.6,12.5z M27,18.6L27,18.6L27,18.6L27,18.6z"
      fill="rgb(255 255 255)"
      fillRule="evenodd"
    />
    <path
      d="M27.1,18.6l-3-4.6l-1.1,1.8l2.9,4.7l1.3-0.6L27.1,18.6z"
      fill="rgb(36 162 238)"
    />
    <path
      d="M25.7,21.1l-3-4.6l-1.3,2l2.4,3.8l1.5-0.6L25.7,21.1z"
      fill="rgb(36 162 238)"
    />
    <path
      clipRule="evenodd"
      d="M15.9,13.4l5.5-3.2l5.5,3.2v6.4l-5.5,3.2l-4.1-2.3l-0.5,0.9l4.5,2.6c0.1,0.1,0.2,0.1,0.3,0l6.3-3.6 c0.1-0.1,0.2-0.2,0.2-0.3v-7.2c0-0.1-0.1-0.2-0.2-0.3l-6.3-3.6c-0.1-0.1-0.2-0.1-0.3,0L15,12.7c-0.1,0.1-0.2,0.2-0.2,0.3v7.2 c0,0.1,0.1,0.2,0.2,0.3l1.3,0.7l0.6-0.9l-0.9-0.5V13.4z"
      fill="rgb(152 188 220)"
      fillRule="evenodd"
    />
    <path
      d="M23.8,13.4l-2.1,0l-4.9,8l1.7,1L23.8,13.4z"
      fill="rgb(255 255 255)"
    />
    <path
      d="M18.5,14.2c0.5-0.8,1.9-0.8,2.5-0.7l-4.8,7.7c-0.2-0.1-0.6-0.3-0.9-0.5c-0.4-0.2-0.5-0.2-0.5-0.5v-0.4 C15.9,18.2,18,15,18.5,14.2z"
      fill="rgb(255 255 255)"
    />
  </svg>
);
