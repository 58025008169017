"use client";

import clsx from "clsx";
import { FunctionComponent, HTMLAttributes, useEffect } from "react";

import { CHAINS } from "@/constants";
import { useChains, useSilverPrice } from "@/hooks";
import { display } from "@/utils";

import styles from "./XSD.module.css";

export const XSD: FunctionComponent<
  Omit<HTMLAttributes<HTMLUListElement>, "children">
> = ({ className, ...props }) => {
  const [chains, getValues] = useChains();
  const silverPrice = useSilverPrice();

  useEffect(() => {
    const chainIds = CHAINS.map(({ id }) => id);

    getValues(chainIds);
  }, [getValues]);

  return (
    <ul {...props} className={clsx(className, styles.root)}>
      {chains.map(({ Icon, id, title, values }) => (
        <li className={styles.item} key={id}>
          <h2 className={styles.heading}>
            <Icon className={styles.icon} />
            {title}
          </h2>
          <dl className={styles.list}>
            <div className={styles.listitem}>
              <dt className={styles.key}>1 gram of silver</dt>
              <dd className={styles.value}>
                {display("silverPrice", silverPrice)}
              </dd>
            </div>
            <div className={styles.listitem}>
              <dt className={styles.key}>Price of XSD</dt>
              <dd className={styles.value}>
                {display("price", silverPrice, values)}
              </dd>
            </div>
            <div className={styles.listitem}>
              <dt className={styles.key}>Discount</dt>
              <dd className={styles.value}>
                {display("discount", silverPrice, values)}
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>
  );
};
