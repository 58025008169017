import { ComponentProps, createContext } from "react";

import { Tabpanel } from "@/components";

type TabpanelProps = ComponentProps<typeof Tabpanel>;

export const TabsContext = createContext<{
  activeLabel: TabpanelProps["label"] | undefined;
}>({
  activeLabel: undefined,
});
