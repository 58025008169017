import type { AppProps } from "next/app";
import Head from "next/head";
import { FunctionComponent } from "react";

import { Layout } from "@/components";

import "@/styles/fonts.css";
import "@/styles/globals.css";

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => (
  <>
    <Head>
      <meta content="width=device-width,initial-scale=0.6" name="viewport" />
    </Head>
    <Layout>
      <Component {...pageProps} />
    </Layout>
  </>
);

export default App;
