"use client";

import clsx from "clsx";
import { FunctionComponent, HTMLAttributes, useEffect, useState } from "react";

import { CHAINS } from "@/constants";
import { useChains, useSilverPrice } from "@/hooks";
import { display } from "@/utils";

import styles from "./Ticker.module.css";

export const Ticker: FunctionComponent<HTMLAttributes<HTMLDListElement>> = ({
  className,
  ...props
}) => {
  const [chains, getValues] = useChains();
  const silverPrice = useSilverPrice();
  const [chainIndex, setChainIndex] = useState<number>(0);
  const chain = chains.at(chainIndex)!;

  useEffect(() => {
    const chainId = CHAINS.at(chainIndex)!.id;

    getValues([chainId]);
  }, [chainIndex, getValues]);

  return (
    <dl
      {...props}
      className={clsx(className, styles.root)}
      role="complementary">
      <div className={clsx(styles.item, styles.dropdown)}>
        <dt className={styles.key}>Chain</dt>
        <dd className={styles.value}>
          <button
            className={styles.button}
            onClick={() => {
              setChainIndex((prevState) =>
                prevState === chains.length - 1 ? 0 : prevState + 1
              );
            }}
            type="button">
            {chain.title}
          </button>
        </dd>
      </div>
      <div className={clsx(styles.item, styles.rewards)}>
        <dt className={styles.key}>Best Rewards</dt>
        <dd className={styles.value}>
          {display("rewards", silverPrice, chain.values)}
        </dd>
      </div>
      <div className={clsx(styles.item, styles.interest)}>
        <dt className={styles.key}>Mint Interest Rate</dt>
        <dd className={styles.value}>
          {display("interest", silverPrice, chain.values)}
        </dd>
      </div>
      <div className={clsx(styles.item, styles.token)}>
        <dt className={styles.key}>BankX Token</dt>
        <dd className={styles.value}>
          {display("token", silverPrice, chain.values)}
        </dd>
      </div>
    </dl>
  );
};
