import clsx from "clsx";
import Image from "next/image";
import { FunctionComponent, HTMLAttributes } from "react";

import styles from "./NFTs.module.css";

const ITEMS = [
  {
    image: "/images/silver.webp",
    title: "Silver Bar",
  },
  {
    image: "/images/transformation.webp",
    title: "Transformation",
  },
  {
    image: "/images/xsd.webp",
    title: "XSD",
  },
  {
    image: "/images/electricity.webp",
    title: "Electricity",
  },
];

export const NFTs: FunctionComponent<
  Omit<HTMLAttributes<HTMLUListElement>, "children">
> = ({ className, ...props }) => (
  <ul {...props} className={clsx(className, styles.root)}>
    {ITEMS.map(({ image, title }) => (
      <li className={styles.item} key={title}>
        <Image
          alt=""
          className={styles.image}
          height={184}
          role="presentation"
          src={image}
          width={302}
        />
        <dl>
          <dt>Currency Creators</dt>
          <dd className={styles.title}>{title}</dd>
        </dl>
      </li>
    ))}
  </ul>
);
