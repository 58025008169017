import type { IconComponent } from "@/types";

export const X: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 42 43"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.97984 0.920013C3.16984 0.920013 0.0898438 4.01001 0.0898438 7.81001V35.39C0.0898438 39.2 3.17984 42.28 6.97984 42.28H34.5598C38.3698 42.28 41.4498 39.19 41.4498 35.39V7.81001C41.4498 4.00001 38.3598 0.920013 34.5598 0.920013H6.97984ZM9.02985 9.78001H16.8499L22.3998 17.67L29.1398 9.78001H31.5999L23.5098 19.25L33.4798 33.42H25.6599L19.2198 24.27L11.3998 33.42H8.93985L18.1098 22.69L9.02985 9.78001ZM12.7999 11.75L26.6998 31.45H29.7198L15.8199 11.75H12.7999Z"
      fill="currentColor"
    />
  </svg>
);
