import clsx from "clsx";
import { FunctionComponent, HTMLAttributes } from "react";

import { SOCIAL_MEDIA } from "@/constants";

import styles from "./SocialMedia.module.css";

export const SocialMedia: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  className,
  ...props
}) => (
  <aside {...props} className={clsx(className, styles.root)}>
    <ul className={styles.list}>
      {SOCIAL_MEDIA.map(({ Icon, title, url }) => (
        <li key={url}>
          <a
            aria-label={title}
            className={styles.link}
            href={url}
            rel="noreferrer"
            target="_blank"
            title={title}>
            <Icon className={styles.icon} />
          </a>
        </li>
      ))}
    </ul>
  </aside>
);
