import type { IconComponent } from "@/types";

export const Telegram: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.68 0C32.1 0 41.36 9.26 41.36 20.68C41.36 32.1 32.1 41.36 20.68 41.36C9.26 41.36 0 32.1 0 20.68C0 9.26 9.26 0 20.68 0ZM8.61 20.5C6.16 21.46 7.59 22.35 7.59 22.35C7.59 22.35 9.68 23.07 11.47 23.61C13.26 24.15 14.22 23.55 14.22 23.55L22.65 17.87C25.64 15.84 24.92 17.51 24.2 18.23C22.65 19.78 20.08 22.23 17.92 24.21C16.96 25.05 17.44 25.76 17.86 26.12C19.41 27.43 23.66 30.12 23.9 30.3C24.2 30.54 25.87 31.61 26.89 31.38C27.91 31.15 28.03 29.77 28.03 29.77L29.52 20.39C30 17.22 30.48 14.29 30.54 13.46C30.72 11.43 28.57 12.26 28.57 12.26C28.57 12.26 23.91 14.17 22.53 14.77L8.6 20.51L8.61 20.5Z"
      fill="currentColor"
    />
  </svg>
);
