import type { IconComponent } from "@/types";

export const Logo: IconComponent = (props) => (
  <svg
    {...props}
    role="presentation"
    version="1.1"
    viewBox="0 0 225 66"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 13.94H21.9C27.36 13.94 30.8 15.18 33.11 17.49C34.52 18.9 35.53 20.93 35.53 23.63V23.74C35.53 28.42 32.71 31.29 28.49 32.75C33.56 34.21 36.83 36.92 36.83 42.33V42.44C36.83 48.75 31.65 53.37 21.68 53.37H0V13.94ZM22.58 26.61C22.58 24.86 21.17 23.91 18.35 23.91H12.84V29.43H18.4C21.22 29.43 22.57 28.47 22.57 26.73V26.62L22.58 26.61ZM19.31 37.43H12.85V43.4H19.26C22.13 43.4 23.6 42.2 23.6 40.47V40.36C23.6 38.64 22.19 37.43 19.32 37.43H19.31Z"
      fill="rgb(255 255 255)"
    />
    <path
      d="M104.7 13.94V31.35L90.67 13.94H78.33V49.74L63.23 13.66H50.16L33.54 53.38H47.62L49.65 48.08H63.45L65.53 53.38H91.39V35.18L106.04 53.38H117.76V13.94H104.69H104.7ZM52.98 38.73L56.59 29.15L60.2 38.73H52.99H52.98Z"
      fill="rgb(255 255 255)"
    />
    <path
      d="M121.38 13.94H134.56V29.15L145.06 13.94H159.69L146.31 33.39L159.69 53.37H144.99L134.56 37.86V53.37H121.38V13.94Z"
      fill="rgb(255 255 255)"
    />
    <path
      d="M190.55 50.35L200.31 65.1H224.39L202.85 32.51L224.41 0H200.61L190.55 14.82L180.49 0H156.69L178.26 32.52L156.72 65.1H180.8L190.55 50.35Z"
      fill="rgb(15 23 48)"
    />
    <path
      d="M190.55 48.72L199.6 62.06H221.04L201.66 32.6L221.12 3.37H199.59L190.55 16.71L181.52 3.37H159.98L179.44 32.61L160.06 62.06H181.5L190.55 48.72Z"
      fill="rgb(12 67 100)"
    />
    <path
      d="M190.55 47.09L182.42 59.03H163.52L180.62 32.7L163.3 6.73999H182.62L190.54 18.6L198.46 6.73999H217.78L200.46 32.7L217.56 59.04H198.66L190.54 47.1L190.55 47.09Z"
      fill="var(--color-blue)"
    />
    <path
      d="M190.55 45.45L197.62 55.99H214.51L199.28 32.78L214.51 10.1H197.41L190.55 20.48L183.7 10.1H166.6L181.82 32.79L166.59 55.99H183.49L190.55 45.45Z"
      fill="rgb(128 184 221)"
    />
    <path
      d="M190.55 43.82L184.32 52.95H169.65L183 32.88L169.74 13.47H184.61L190.54 22.37L196.47 13.47H211.34L198.08 32.88L211.43 52.96H196.76L190.53 43.83L190.55 43.82Z"
      fill="rgb(255 255 255)"
    />
  </svg>
);
