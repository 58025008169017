import { ethers, formatUnits } from "ethers";

import { UNDEFINED_VALUE } from "@/constants";
import type { Address, Chain, Method } from "@/types";

export const display = (
  type: "discount" | "interest" | "price" | "rewards" | "silverPrice" | "token",
  silverPrice?: number | undefined,
  values?: Chain["values"]
): string => {
  switch (type) {
    case "discount":
      if (
        !values ||
        values.xsd_price === undefined ||
        silverPrice === undefined
      ) {
        return UNDEFINED_VALUE;
      }

      return `${(
        Math.max(0, -((values.xsd_price - silverPrice) / silverPrice)) * 100
      ).toFixed(2)}%`;
    case "interest":
      if (!values || values.interest_rate === undefined) {
        return UNDEFINED_VALUE;
      }

      return `${(values.interest_rate * 100).toFixed(2)}%`;
    case "price":
      if (!values || values.xsd_price === undefined) {
        return UNDEFINED_VALUE;
      }

      return `$${values.xsd_price.toFixed(2)}`;
    case "rewards":
      if (
        !values ||
        values.xsd_price === undefined ||
        silverPrice === undefined
      ) {
        return UNDEFINED_VALUE;
      }

      const percentage = (values.xsd_price - silverPrice) / silverPrice;

      if (percentage < -0.1) {
        return "Buy XSD";
      }

      if (percentage > 0.1) {
        return "Sell XSD";
      }

      if (values.amountpaid1 === undefined || values.diff1 === undefined) {
        return UNDEFINED_VALUE;
      }

      if (values.diff1 - values.amountpaid1) {
        return "Liquidity Rewards - XSD Pool";
      }

      if (values.amountpaid2 === undefined || values.diff2 === undefined) {
        return UNDEFINED_VALUE;
      }

      if (values.diff2 - values.amountpaid2) {
        return "Liquidity Rewards - BankX Pool";
      }

      if (values.LPBBonusPercent === undefined) {
        return UNDEFINED_VALUE;
      }

      if (values.LPBBonusPercent > 20) {
        return "Token Lockup Rewards";
      }

      return "Mint XSD";
    case "silverPrice":
      if (silverPrice === undefined) {
        return UNDEFINED_VALUE;
      }

      return `$${silverPrice.toFixed(2)}`;
    case "token":
      if (
        !values ||
        values.genesis_supply === undefined ||
        values.totalSupply === undefined
      ) {
        return UNDEFINED_VALUE;
      }

      const difference = values.totalSupply - values.genesis_supply;

      if (difference > 0) {
        return "Inflationary";
      }

      if (difference < 0) {
        return "Deflationary";
      }

      return "Zero";
    default:
      return UNDEFINED_VALUE;
  }
};

export const fetch =
  (address: Address, method: Method) =>
  ({ addresses, provider }: Chain): Promise<bigint> | null =>
    provider && addresses[address]
      ? new ethers.Contract(
          addresses[address],
          [`function ${method}() view returns (uint256)`],
          provider
        )[method]()
      : null;

export const getJSON =
  (
    url: Parameters<typeof window.fetch>[0],
    params: Record<string, string> = {}
  ) =>
  async () => {
    const urlSearchParams = new URLSearchParams(params);
    const response = await window.fetch(
      `${url}${urlSearchParams.size !== 0 ? `?${urlSearchParams}` : ""}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return await response.json();
  };

export const toNumber =
  (decimals: number) =>
  (result: PromiseSettledResult<bigint | null>): number | undefined =>
    result.status === "fulfilled" && result.value !== null
      ? Number(formatUnits(result.value, decimals))
      : undefined;
