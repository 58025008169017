import clsx from "clsx";
import { FunctionComponent, IframeHTMLAttributes } from "react";

import styles from "./Video.module.css";

export const Video: FunctionComponent<
  IframeHTMLAttributes<HTMLIFrameElement>
> = ({ className, ...props }) => (
  <iframe {...props} className={clsx(className, styles.root)} />
);
