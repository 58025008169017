import Link from "next/link";
import { FunctionComponent, PropsWithChildren } from "react";

import { Button, Nav, SocialMedia } from "@/components";
import { Logo } from "@/icons";

import styles from "./Layout.module.css";

export const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className={styles.root}>
    <header className={styles.header}>
      <Link aria-label="BankX" className={styles.logo} href="/">
        <Logo />
      </Link>
      <Nav className={styles.nav} />
      <SocialMedia className={styles.socialMedia} />
      <Button className={styles.button} href="https://app.bankx.io/">
        Launch BankX
      </Button>
    </header>
    <main className={styles.main}>{children}</main>
  </div>
);
