import clsx from "clsx";
import { FunctionComponent, HTMLAttributes } from "react";

import styles from "./Tab.module.css";

export const Tab: FunctionComponent<
  HTMLAttributes<HTMLLabelElement> & {
    active?: boolean;
  }
> = ({ active, children, className, ...props }) => (
  <label
    {...props}
    className={clsx(className, styles.root, {
      [styles.active]: active,
    })}>
    <span className={styles.background} role="presentation" />
    {children}
    <span className={styles.underline} role="presentation" />
  </label>
);
