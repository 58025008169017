"use client";

import useSWR from "swr";

import { GRAMS_PER_TROY_OUNCE } from "@/constants";
import { getJSON } from "@/utils";

const ID = "f2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e";
const URL = "https://hermes.pyth.network/v2/updates/price/latest";

type Response = {
  parsed: {
    id: typeof ID;
    price: {
      expo: string;
      price: string;
    };
  }[];
};

export const useSilverPrice = (): number | undefined => {
  const { data } = useSWR<Response>(
    "silverPrice",
    getJSON(URL, {
      "ids[]": ID,
    })
  );
  const obj = data?.parsed.find(({ id }) => id === ID);

  if (!obj) {
    return undefined;
  }

  const {
    price: { expo, price },
  } = obj;

  return (
    Number(price) / Math.pow(10, Math.abs(Number(expo))) / GRAMS_PER_TROY_OUNCE
  );
};
