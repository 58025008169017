import clsx from "clsx";
import {
  AnchorHTMLAttributes,
  FunctionComponent,
  PropsWithChildren,
} from "react";

import styles from "./Button.module.css";

// @todo only add `rel` and `target` for external links
export const Button: FunctionComponent<
  PropsWithChildren<
    AnchorHTMLAttributes<HTMLAnchorElement> & {
      animate?: boolean;
    }
  >
> = ({ animate = false, children, className, ...props }) => (
  <a
    {...props}
    className={clsx(className, styles.root, {
      [styles.animate]: animate,
    })}
    rel="noreferrer"
    target="_blank">
    <span className={styles.wires} />
    <span className={styles.background} />
    {children}
  </a>
);
