"use client";

import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  FunctionComponent,
  HTMLAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { Tab } from "@/components";
import { NAV_ITEMS } from "@/constants";

import styles from "./Nav.module.css";

export const Nav: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  className,
  ...props
}) => {
  const pathname = usePathname();
  const dialog = useRef<HTMLDialogElement>(null);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const isActive = useCallback<(url: string) => boolean>(
    (url) => (url === "/" ? url === pathname : pathname.startsWith(url)),
    [pathname]
  );
  const isCurrentPath = useCallback<(url: string) => boolean>(
    (url) => url === pathname,
    [pathname]
  );
  const isExternal = useCallback<(url: string) => boolean>(
    (url) => !url.startsWith("/"),
    []
  );
  const activeTitle = useMemo<string | undefined>(
    () => NAV_ITEMS.find(({ url }) => isActive(url))?.title,
    [isActive]
  );

  return (
    <nav
      {...props}
      className={clsx(className, styles.root)}
      onMouseLeave={() => {
        setExpandedId(null);
      }}>
      <button
        aria-label="Navigation Menu"
        className={styles.button}
        onClick={() => {
          dialog.current?.showModal();
        }}
        type="button">
        {activeTitle}
      </button>
      <dialog
        className={styles.dialog}
        onClick={() => {
          dialog.current?.close();
        }}
        ref={dialog}>
        <ul className={styles.list}>
          {NAV_ITEMS.map(({ id, items, title, url }) => (
            <li className={styles.listitem} key={id}>
              <Link
                aria-current={isCurrentPath(url) ? "page" : false}
                className={clsx(styles.link, {
                  [styles.active]: isActive(url),
                })}
                href={url}
                target={isExternal(url) ? "_blank" : undefined}>
                {isActive(url) ? <Tab active>{title}</Tab> : title}
              </Link>
              {items && (
                <ul className={styles.menu}>
                  {items.map(({ id, title, url }) => (
                    <li className={styles.menuitem} key={id}>
                      <Link
                        aria-current={isCurrentPath(url) ? "page" : false}
                        className={styles.link}
                        href={url}
                        target={isExternal(url) ? "_blank" : undefined}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </dialog>
      <ul className={styles.list}>
        {NAV_ITEMS.map(({ id, items, title, url }) => (
          <li
            className={styles.listitem}
            key={id}
            onMouseEnter={() => {
              setExpandedId(id);
            }}>
            <Link
              aria-current={isCurrentPath(url) ? "page" : false}
              className={clsx(styles.link, {
                [styles.active]: isActive(url),
              })}
              href={url}
              onClick={() => {
                setExpandedId(null);
              }}
              target={isExternal(url) ? "_blank" : undefined}>
              {isActive(url) ? <Tab active>{title}</Tab> : title}
            </Link>
            {items && (
              <ul
                className={clsx(styles.menu, {
                  "visually-hidden": expandedId !== id,
                })}>
                {items.map(({ id, title, url }) => (
                  <li className={styles.menuitem} key={id}>
                    <Link
                      aria-current={isCurrentPath(url) ? "page" : false}
                      className={styles.link}
                      href={url}
                      onClick={(e) => {
                        const element = e.target as HTMLAnchorElement;
                        const isFocusVisible =
                          element.matches(":focus-visible");

                        setExpandedId((prevState) =>
                          prevState === id ? null : prevState
                        );

                        if (!isFocusVisible) {
                          element.blur();
                        }
                      }}
                      target={isExternal(url) ? "_blank" : undefined}>
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
