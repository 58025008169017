"use client";

import clsx from "clsx";
import {
  Children,
  ComponentProps,
  ContextType,
  FunctionComponent,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TabsContext } from "@/contexts";

import { Tab } from "../Tab";
import { Tabpanel } from "../Tabpanel";

import styles from "./Tabs.module.css";

type TabpanelProps = ComponentProps<typeof Tabpanel>;

export const Tabs: FunctionComponent<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...props }) => {
  const labels = useMemo<string[]>(
    () =>
      Children.toArray(children)
        .filter(isValidElement<TabpanelProps>)
        .map(({ props }) => props.label),
    [children]
  );
  const [activeLabel, setActiveLabel] = useState<
    TabpanelProps["label"] | undefined
  >(labels.at(0));
  const contextValue = useMemo<ContextType<typeof TabsContext>>(
    () => ({
      activeLabel,
    }),
    [activeLabel]
  );

  useEffect(() => {
    // if the tabs change and the currently active one no longer exists
    if (activeLabel !== undefined && !labels.includes(activeLabel)) {
      setActiveLabel(labels.at(0));
    }
  }, [activeLabel, labels]);

  return (
    <div {...props} className={clsx(className, styles.root)}>
      <div aria-hidden className={styles.tablist}>
        {labels.map((label) => (
          <Tab
            active={label === activeLabel}
            key={label}
            onClick={() => {
              setActiveLabel(label);
            }}
            tabIndex={-1}>
            {label}
          </Tab>
        ))}
      </div>
      <TabsContext.Provider value={contextValue}>
        {children}
      </TabsContext.Provider>
    </div>
  );
};
