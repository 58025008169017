import {
  Arbitrum,
  Avalanche,
  BASE,
  BNB,
  ETH,
  Optimism,
  Polygon,
  Pulsechain,
  Rumble,
  Telegram,
  X,
  YouTube,
} from "@/icons";
import type { Chain, NavItem, SocialMedium } from "@/types";

export const CHAINS: Chain[] = [
  {
    addresses: {
      bankx: "0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a",
      cd: "0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640",
      pid: "0xa6FD872F0F6cf467Cd0c2B8352d9E5046D6926A9",
    },
    Icon: ETH,
    id: BigInt(1),
    title: "ETH",
    url: "wss://ethereum-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0x4d369BD339709021267E92702A9E4cE30be85706",
      cd: "0xED024d771261D765B9Dc7b0947ef271ea006248F",
      pid: "0xf441252de7972b269ce954d82be8b127a815ecfb",
    },
    Icon: BNB,
    id: BigInt(56),
    title: "BNB",
    url: "wss://bsc-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0x93Abe713002526D4cE708f890139f19313012f95",
      cd: "0xc79461C653bCd0137D062ec87B9b821f7c9bCc26",
      pid: "0x9f5f98657e714cfbb5af899b722685e8e7f71b7d",
    },
    Icon: Arbitrum,
    id: BigInt(42161),
    title: "Arbitrum",
    url: "wss://arbitrum-one-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0xeeA52F6587F788cc12d0b5a28c48e61866c076F0",
      cd: "0xc79461C653bCd0137D062ec87B9b821f7c9bCc26",
      pid: "0x3f0e5111785ecf0d0e25bf32bbf1a1b458757fd8",
    },
    Icon: Polygon,
    id: BigInt(137),
    title: "Polygon",
    url: "wss://polygon-bor-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0x93Abe713002526D4cE708f890139f19313012f95",
      cd: "0xc79461C653bCd0137D062ec87B9b821f7c9bCc26",
      pid: "0xbc94a15b50ebe1853a6bce93eaecd8705909460a",
    },
    Icon: Optimism,
    id: BigInt(10),
    title: "Optimism",
    url: "wss://optimism-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0x2147F5c02c2869E8C2d8F86471d3d7500355d698",
      cd: "0xc79461C653bCd0137D062ec87B9b821f7c9bCc26",
      pid: "0x284c10042e9f69d44e52b6d16e1fa33fc944e0c2",
    },
    Icon: BASE,
    id: BigInt(8453),
    title: "BASE",
    url: "wss://base-rpc.publicnode.com",
  },
  {
    addresses: {
      bankx: "0x2147F5c02c2869E8C2d8F86471d3d7500355d698",
      cd: "0x3B5092fd38649c45c5418c319f9bA1ee02f84672",
      pid: "0x3f0e5111785ecf0d0e25bf32bbf1a1b458757fd8",
    },
    Icon: Avalanche,
    id: BigInt(43114),
    title: "Avalanche",
    url: "wss://avalanche-c-chain-rpc.publicnode.com",
  },
  {
    addresses: {},
    Icon: Pulsechain,
    id: BigInt(369),
    title: "Pulsechain",
    url: "wss://rpc.pulsechain.com",
  },
];

export const GRAMS_PER_TROY_OUNCE = 31.1035;

export const NAV_ITEMS: NavItem[] = [
  {
    id: "home",
    title: "Home",
    url: "/",
  },
  {
    id: "about",
    title: "About",
    url: "/about",
  },
  {
    id: "stablecoin",
    title: "Stablecoin",
    url: "/stablecoin",
  },
  {
    id: "nft",
    items: [
      {
        id: "nft__buy",
        title: "Buy NFT",
        url: "https://app.bankx.io/#/buy-nft",
      },
      {
        id: "nft__leaderboard",
        title: "Leaderboard",
        url: "https://app.bankx.io/#/nft-leaderboard",
      },
      {
        id: "nft__referrals",
        title: "Referral Resources",
        url: "/nft/referrals",
      },
    ],
    title: "NFT",
    url: "/nft",
  },
  /*
  {
    id: "ai",
    title: "AI",
    url: "https://www.AIDeFi.io/",
  },
  */
  {
    id: "terms",
    title: "Terms",
    url: "/terms",
  },
  {
    id: "docs",
    title: "Docs",
    url: "https://docs.bankx.io/",
  },
];

export const SOCIAL_MEDIA: SocialMedium[] = [
  {
    Icon: Telegram,
    title: "Telegram",
    url: "https://t.me/BankXio",
  },
  {
    Icon: YouTube,
    title: "YouTube",
    url: "https://www.youtube.com/@BankXio",
  },
  {
    Icon: X,
    title: "X",
    url: "https://x.com/bankxio",
  },
  {
    Icon: Rumble,
    title: "Rumble",
    url: "https://rumble.com/c/BankXio",
  },
];

export const UNDEFINED_VALUE = "-";
